<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center mt-2">
      <div class="col-12">
        <div class="card card-fullheight">
          <div class="card-body p-3">
            <h5 class="box-title mb-3 mt-1">Detalhes do Estoque de Espaços</h5>

            <!-- Superior -->
            <div class="row">
              <!-- Contrato -->
              <div class="col-lg-8">
                <div class="row">
                  <div class="col-lg-12 px-0 pb-3">
                    <small class="custom-label">Contrato</small>
                    <div class="text-capitalize">
                      {{ booking.idcontrato_frete ? booking.idcontrato_frete.numero_contrato : '-' }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- Grupos -->
              <div class="col-lg-4">
                <div class="row">
                  <div class="col-lg-12 px-0 pb-3">
                    <small class="custom-label">Grupo<span v-if="contractGroups.length > 1">s</span></small>
                    <div v-if="contractGroups.length > 0">
                      <div v-for="(group, index) in contractGroups" :key="index" class="text-capitalize">
                        <a v-bind:href="$util.mountLink('BookingContractGroupView', group)">{{ group.nome }}</a>
                        <span v-if="index != Object.keys(contractGroups).length - 1">, </span>
                      </div>
                    </div>
                    <div v-else>
                      <div>-</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Informações gerais -->
            <div class="row">

              <!-- Esquerda -->
              <div class="col-lg-4">
                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">TEUs Contratados</small>
                    <div class="text-capitalize">
                      {{ booking.teus_contratados ? booking.teus_contratados : '-' }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">TEUs Utilizados</small>
                    <div class="text-capitalize">
                      {{ usedBookings }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">TEUs Disponíves</small>
                    <div class="text-capitalize" :style="{'color': contractStatus.color}">
                      {{ remainingBookings }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- Centro -->
              <div class="col-lg-4">
                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">ETD</small>
                    <div class="text-capitalize">
                      {{ formatDateTime(booking.etd) }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Dead Line</small>
                    <div class="text-capitalize">
                      {{ formatDateTime(booking.dead_line) }}
                    </div>
                  </div>

                  <div class="col-lg-12 px-0 pb-3">
                    <small class="custom-label">Semana</small>
                    <div class="text-capitalize">
                      {{ booking.semana ? booking.semana : '-' }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- Direita -->
              <div class="col-lg-4">
                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Multa</small>
                    <div class="text-capitalize">
                      {{ booking.possui_multa ? $util.formatToPrice(booking.valor_multa) : 'Não possui' }}
                    </div>
                  </div>

                  <!-- <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Criação</small>
                    <div class="text-capitalize">
                      {{ formatDateTime(booking.data_criacao) }}
                    </div>
                  </div> -->

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Situação</small>
                    <div class="text-capitalize" :style="{'color': contractStatus.color}">
                      {{ contractStatus.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Observação -->
            <hr v-if="booking.observacao">
            <div class="row" v-if="booking.observacao">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-12 px-0">
                    <small class="custom-label">Observação</small>
                    <div class="text-capitalize">
                      {{ booking.observacao }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr>

            <!-- Botões de ação -->
            <div class="row">
              <div class="col-12 px-0">
                <a v-if="canEdit && this.booking.id" class="btn btn-sm btn-outline-light btn-a-color mx-1 pull-right"
                  v-bind:href="$util.mountLink('BookingContractEdit', this.booking)">Editar</a>

                <button type="button" class="btn btn-sm btn-outline-light mx-1 pull-right" @click="onCancel">
                  Listagem
                </button>

                <!-- <button type="button" class="btn btn-link btn-sm mx-1 pull-right" @click="onShow">
                  Console
                </button> -->

                <button type="button" class="btn btn-link btn-sm mx-1 pull-left" @click="onDelete">
                  Excluir
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Listados -->
      <div class="col-12 pull-right">
        <div class="card card-fullheight">
          <div class="card-body p-3">
            <h5 class="box-title mb-3 mt-1">Processos Listados</h5>
            <hr>
            <!-- Table -->
            <div class="table-responsive px-0">
              <vuetable ref="vuetable"
                :noDataTemplate="this.$i18n.t('empty')"
                :api-url="endpoint"
                :fields="vTableFields"
                :sort-order="sortOrder"
                :css="css.table"
                pagination-path="pagination"
                :per-page="20"
                :multi-sort="multiSort"
                :append-params="filter"
                multi-sort-key="shift"
                :http-options="httpOptions"
                track-by="id_process"
                @vuetable:pagination-data="onPaginationData"
                @vuetable:loading="onLoading"
                @vuetable:loaded="onLoaded"
                @vuetable:cell-clicked="onRowClicked">
              </vuetable>

            </div>
            <vuetable-pagination ref="pagination"
              :css="css.pagination" @vuetable-pagination:change-page="onChangePage"
            ></vuetable-pagination>
            <!-- END Table -->
          </div>
        </div>
      </div>

      <!-- Total do Grupo -->
      <div class="col-12 pull-right">
        <div class="card card-fullheight">
          <div class="card-body p-3">
            <h5 class="box-title mb-3 mt-1">Total do Grupo</h5>
            <hr>
            <div class="row">
              <div class="col-md-3">
                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">TEUs Contratados</small>
                    <div class="text-capitalize">
                      {{ groupInfo ? groupInfo.totalContracted : '-'}}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">TEUs Utilizados</small>
                    <div class="text-capitalize">
                      {{ groupInfo ? groupInfo.totalUsed : '-'}}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">TEUs Disponíves</small>
                    <div class="text-capitalize" :style="{'color': groupStatus.color}">
                      {{ groupInfo ? groupInfo.totalAvailable : '-'}}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Situação</small>
                    <div class="text-capitalize" :style="{'color': groupStatus.color}">
                      {{ groupStatus.label }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-9">
                <small class="custom-label">Contratos do Grupo</small>
                <div v-if="groupInfo" class="mt-2">
                  <div v-for="(bookingGroup, index) in groupInfo.records" :key="index" class="row pb-3">
                    <div class="col-12">
                      <small class="custom-label">Contrato: </small>
                      {{ bookingGroup.contrato }}
                    </div>
                    <div class="col-3">
                      <small class="custom-label">Contratado: </small>
                      {{ bookingGroup.contratado }}
                    </div>
                    <div class="col-3">
                      <small class="custom-label">Utilizado: </small>
                      {{ bookingGroup.utilizado }}
                    </div>
                    <div class="col-3">
                      <small class="custom-label">Disponível: </small>
                      <span :style="{'color': formatStatus(bookingGroup.situacao).color}">{{ bookingGroup.contratado - bookingGroup.utilizado }}</span>
                    </div>
                    <div class="col-3">
                      <small class="custom-label">Situação: </small>
                      <span :style="{'color': formatStatus(bookingGroup.situacao).color}">{{ formatStatus(bookingGroup.situacao).label }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Aplicáveis -->
      <!-- <div class="col-md-6 pull-right">
        <div class="card card-fullheight">
          <div class="card-body p-3">
            <h5 class="box-title mb-3 mt-1">Aplicáveis</h5>
            <hr>
            <div v-if="applicableProcesses.length > 0">
              <div class="row px-0 pb-1" v-for="process in applicableProcesses" :key="process.id">
                <div class="col-8">
                  <small style="color:grey">
                  <router-link :to="{ path: `/processdetail?${process.id}`}">
                    {{ process.processo }}
                  </router-link></small>
                </div>
                <div class="col-4 text-right">
                  <small style="color:grey">
                  {{ process.totalTeus }} TEUS</small>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row">
                <div class="col-12">
                  <small class="font-italic">Não foram encontrados processos aplicáveis</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Mensagens -->
      <div class="col-12">
        <div class="card card-fullheight">
          <div class="card-body p-3">
            <h5 class="box-title mb-3 mt-1">Mensagens</h5>

            <hr v-if="hasMessages">

            <div class="row" v-if="hasMessages">
              <div class="col-12 px-0 py-2" v-for="comentario in booking.comentarios" :key="comentario.id">
                <small style="color:grey">{{ comentario.id_proprietario.id_pessoa.nome }}, em {{ formatDateTime(comentario.data_criacao) }}</small>
                <div>{{ comentario.texto }}</div>
              </div>
            </div>

            <hr>

            <!-- Nova mensagem -->
            <div class="col-md-12 my-2">
              <label class="my-1">Nova mensagem</label>
              <custom-textarea
                v-model="newMessage"
                name="newMessage"
                type="text"
                rootClassName="md-form my-0"
                inputClassName="md-form-control mt-0"
                :rows="2"
                v-validate="{ required: false }"
                :error="errors.first('newMessage')">
              </custom-textarea>
              <button type="button" class="btn btn-sm btn-outline-light pull-right"
                :disabled="!newMessage" @click="onSaveMessage">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Assinantes -->
      <div class="col-md-6">
        <div class="card card-fullheight">
          <div class="card-body p-3">
            <h5 class="box-title mb-3 mt-1">Assinantes</h5>
            <hr>

            <div class="row" v-for="assinante in booking.assinantes" :key="assinante.id">
              <div class="col-12 px-1">
                <small>{{ assinante.id_user.name }} ({{ assinante.id_user.email }})</small>
              </div>
            </div>

            <hr>

            <div class="row">
              <div class="col-12 px-0">
                  <a v-if="canEdit" class="btn btn-sm btn-outline-light pull-right mx-1"
                    v-bind:href="$util.mountLink('BookingFollowers', this.booking)">Editar</a>

                <button class="btn btn-sm btn-outline-light pull-right mx-1" @click="toogleSubscribe()">
                  <span v-if="isFollowing">Deixar de acompanhar</span>
                  <span v-if="!isFollowing">Acompanhar</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Histórico de alterações de situação -->
      <!-- <div class="col-md-6">
        <div class="card card-fullheight">
          <div class="card-body p-3">
            <h5 class="box-title mb-1 mt-1">Alterações de situação</h5>
            <hr>
            <div v-if="booking.historico_situacao && booking.historico_situacao.length">
              <div class="row" v-for="alteracao in booking.historico_situacao" :key="alteracao.id">
                <div class="col-12 p-1">
                  <small>De <span :style="{'color': statusColor(alteracao.situacao_anterior)}">
                    {{ fullStatus(alteracao.situacao_anterior) }}
                  </span> para <span :style="{'color': statusColor(alteracao.situacao_atualizada)}">
                    {{ fullStatus(alteracao.situacao_atualizada) }}
                  </span>, por {{ alteracao.responsavel.name }}, em {{ formatDateTime(alteracao.data) }}</small>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row">
                <div class="col-12 p-1">
                  <span>Sem registros</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Merge -->
      <!-- <div v-if="mergeOptions.length && canEdit" class="col-md-6">
        <div class="card card-fullheight">
          <div class="card-body p-3">
            <h5 class="box-title mb-3 mt-1">Opções de mesclagem</h5>
            <hr>

            <div class="row" v-for="record in mergeOptions" :key="record.id">
              <div class="col-11 px-1">
                <a v-bind:href="$util.mountLink('BookingView', record)">
                  Id: {{ record.id }} - Criado em: {{ $util.formatDateTime(record.data_criacao) }}</a>
              </div>
              <div class="col-1 pull-right">
                <input v-model="record.selected" value="1" type="checkbox" :id="checkboxId(record)">
              </div>
            </div>

            <hr>

            <div class="row">
              <div class="col-12 px-0">
                <button :disabled="!countMergeSelected" class="btn btn-sm btn-outline-light pull-right mx-1" @click="mergeSelected()">
                  <span>Mesclar registros</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Slot vazio -->
      <div class="col-md-6 pull-right">
        <div class="card card-fullheight">
          <div class="card-body p-3">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue'
import Vuetable, { VuetablePagination } from 'vuetable-2'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import CustomTextarea from '@/components/Forms/CustomTextarea.vue'

// Services
import BookingService from '@/services/BookingService'
import ContratoFreteService from '@/services/ContratoFreteService'

export default {
  name: 'BookingDetail',
  components: {
    Loading,
    CustomTextarea,
    Vuetable,
    VuetablePagination
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      endpoint: process.env.VUE_APP_APIENDPOINT + 'v2/booking/' + this.$route.params.id + '/contract/listed/processes',
      fieldsTable: [
        {
          name: 'processo',
          title: this.$i18n.t('kpi.process.title'),
          sortField: 'processo',
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'origem',
          title: this.$i18n.t('form.booking-control.origem'),
          sortField: 'origem',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'destino',
          title: this.$i18n.t('form.booking-control.destino'),
          sortField: 'destino',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'totalContainer20',
          title: this.$i18n.t('booking.containers-20'),
          sortField: 'totalContainer20',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'totalContainer40',
          title: this.$i18n.t('booking.containers-40'),
          sortField: 'totalContainer40',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'totalTeus',
          title: this.$i18n.t('booking.total-teus'),
          sortField: 'totalTeus',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      sortOrder: [
        { field: 'processo', direction: 'asc' }
      ],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      },
      filter: {},
      multiSort: true,
      booking: {
        armador: null,
        cliente: null,
        vendedor: null,
        solicitante: null,
        processo: null,
        tipoOperacao: {
          id: 2,
          name: 'IMPORT'
        },
        origem: null,
        destino: null,
        navio: null,
        mercadoria: null,
        equipamentos: [],
        numero: null,
        situacao: null,
        etd: null,
        ddl: null,
        possuiMulta: null,
        valorMulta: null,
        observacao: null,
        dataCriacao: null,
        dataSolicitacao: null,
        send_email: 0,
        contrato: null,
        semana: null
      },
      subscribers: [],
      newMessage: null,
      statusOptions: [
        {
          color: '#f1ba26',
          label: 'Disponível'
        },
        {
          color: '#5ec65f',
          label: 'Completo'
        },
        {
          color: '#ff0000',
          label: 'Overbooked'
        }
      ],
      statusList: [
        {
          color: '#f1ba26',
          label: 'Disponível'
        },
        {
          color: '#5ec65f',
          label: 'Completo'
        },
        {
          color: '#ff0000',
          label: 'Overbooked'
        }
      ],
      operationOptions: [
        'Exportação',
        'Importação'
      ],
      mergeOptions: [],
      listedProcesses: [],
      groupInfo: null,
      contractGroups: [],
      applicableProcesses: []
    }
  },
  // Apply filters to local filter
  beforeMount () {
    // this.statusFetchOptions()
    this.loadBookingInfo()
    this.loadListedProcesses()
    this.loadGroupInfo()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  methods: {
    onShow () {
    },
    toogleSubscribe () {
      let _this = this
      _this.subsLoaded = false
      let formData = new FormData()
      formData.append('booking', this.booking.id)

      BookingService.toogleSubscribe(formData).then(res => {
        let subs = res.data.data.subscribers.map(x => (
          x.id_user.id
        ))
        _this.subscribers = subs
        this.booking.assinantes = res.data.data.subscribers
        _this.subsLoaded = true
      })
    },
    loadBookingInfo () {
      this.isLoading = true
      let id = this.$route.params.id

      BookingService.getBooking(id).then(res => {
        this.booking = res.data.data

        let subs = res.data.data.assinantes.map(x => (
          x.id_user.id
        ))
        this.subscribers = subs
        this.loadGroups(this.booking.idcontrato_frete.id)

        this.isLoading = false
      })
    },
    loadListedProcesses () {
      this.isLoading = true
      let id = this.$route.params.id

      BookingService.getContractListedProcesses(id).then(res => {
        this.listedProcesses = res.data.data
      }).finally(() => {
        this.isLoading = false
      })
    },
    loadGroups (id) {
      this.isLoading = true

      ContratoFreteService.getGroups(id).then(res => {
        this.contractGroups = res.data.data
      }).finally(() => {
        this.isLoading = false
      })
    },
    loadGroupInfo () {
      this.isLoading = true
      let id = this.$route.params.id

      BookingService.getGroupWeekTotal(id).then(res => {
        this.groupInfo = res.data.data
      }).finally(() => {
        this.isLoading = false
      })
    },
    statusFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        BookingService.getStatusOptions(filters).then(res => {
          _this.statusOptions = res.data.data
          resolve(_this.statusOptions)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    onRowClicked (row) {
      this.$router.push({ path: `/processdetail?${row.data.id}` })
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'BookingContractIndex' })
    },
    fullStatus (value) {
      return this.statusOptions.length ? this.statusOptions[value] : 'não informado'
    },
    statusColor (value) {
      return parseInt(value) ? this.colors[value] : '#000000'
    },
    formatDateTime (date) {
      return this.$util.formatDateTime(date, 'pt-BR', ' - ')
    },
    onDelete () {
      this.$alertSwal.modalConfirm('', 'Você tem certeza que deseja excluir este booking?',
        'warning', 'Sim', 'Não', (e) => this.deleteBooking(this.booking.id)).then((result) => {
        if (result.value) {
          this.$alertSwal.toast('Registro excluído com sucesso!')
        } else {
          this.$alertSwal.toast('Não foi possível excluir o registro!', 'error')
        }

        this.$router.push({ name: 'BookingContractIndex' })
      })
    },
    async deleteBooking (id) {
      let _this = this
      _this.isLoading = true
      await BookingService.deleteBooking(id).then(res => {
        // if (res.httpCode === 200 && res.data.status === 'ok') {
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    },
    onUpdateStatus (booking, newStatus) {
      let currentStatusLabel = this.statusOptions[booking.situacao]
      let newStatusLabel = this.statusOptions[newStatus]
      let message = `Confirma a alteração de situação de "${currentStatusLabel}" para "${newStatusLabel}"?`

      this.$alertSwal.modalConfirm('', message,
        'warning', 'Sim', 'Não', (e) => this.updateBookingStatus(booking.id, newStatus)).then((res) => {
        if (res.value.success) {
          this.$alertSwal.toast(res.value.message)
        } else {
          let message = `Não foi possível alterar o registro!<br/> ${res.value.message}`
          this.$alertSwal.toast(message, 'error')
        }
        this.$refs.vuetable.refresh()
      })
    },
    async updateBookingStatus (bookingId, newStatusKey) {
      let _this = this
      _this.isLoading = true

      let data = {
        id: bookingId,
        situacao: newStatusKey
      }

      let resp = await BookingService.updateBookingStatus(data).then(res => {
        _this.isLoading = false
        return {
          success: true,
          message: res.data.message,
          record: res.data.data
        }
      }).catch((e) => {
        _this.isLoading = false
        return {
          success: false,
          message: e.message
        }
      })

      return resp
    },
    refreshPage () {
      this.loadBookingInfo()
    },
    onSaveMessage () {
      let me = this
      let formData = new FormData()
      formData.append('booking', this.booking.id)
      formData.append('message', this.newMessage)
      me.isLoading = true
      BookingService.sendMessage(formData).then(dataJson => {
        this.booking.comentarios.push(dataJson.data.data)
        me.isLoading = false
        me.newMessage = ''
      }).then(function (res) {
        let msg = `<h4>Sua mensagem foi enviada com sucesso!</h4>`
        global.instanceApp.$alertSwal.modalAlert(null, msg, 'success', '').then((result) => {})
        me.isLoading = false
        me.isPageLoading = false
      }).catch(() => {
      })
    },
    checkboxId (record) {
      return 'selected'.concat(record.id)
    },
    mergeSelected () {
      this.isLoading = true

      let items = this.mergeOptions.map(x => (
        x.selected ? x.id : null)
      )

      let formData = new FormData()
      formData.append('id', this.booking.id)
      formData.append('merge', JSON.stringify(items))
      BookingService.mergeBookings(formData).then(response => {
        this.loadBookingInfo()
        this.isLoading = false
      }).catch(err => {
        if (err.code === 400) {
          this.displayErrors(err.errors)
        }
        this.isLoading = false
      })
    },
    formatStatus (value) {
      if (!value) {
        return {
          color: '#000000',
          label: '-'
        }
      }

      return this.statusList[value - 1]
    }
  },
  computed: {
    vTableFields () {
      return this.fieldsTable
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    canEdit () {
      return this.isSuperAdmin || this.isBookingAdmin
    },
    canTakeOrThrow () {
      return (this.isBookingRequester && this.isAvailable) || this.isTheSeller
    },
    isSuperAdmin () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isBookingAdmin () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_MANAGER') !== -1) {
        return true
      }
      return false
    },
    isBookingRequester () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_REQUESTER') !== -1) {
        return true
      }
      return false
    },
    isAvailable () {
      return !this.booking.id_vendedor
    },
    isTheSeller () {
      let userInfo = this.user || null
      if (userInfo && this.booking.id_vendedor && userInfo.active_employee.id_table_origin === this.booking.id_vendedor.id) {
        return true
      }
      return false
    },
    isFollowing () {
      return this.subscribers && this.user && this.subscribers.includes(this.user.id)
    },
    color () {
      return parseInt(this.booking.situacao) !== null ? this.colors[this.booking.situacao] : '#000000'
    },
    status () {
      return this.statusOptions.length ? this.statusOptions[this.booking.situacao] : '-'
    },
    operacao () {
      return this.operationOptions[this.booking.tipo_operacao - 1]
    },
    countMergeSelected () {
      return this.mergeOptions.map(merge => merge.selected)
        .reduce((prev, curr) => prev + curr, 0)
    },
    hasMessages () {
      return this.booking.comentarios && this.booking.comentarios.length > 0
    },
    usedBookings () {
      if (!this.booking.teus_contratados) {
        return '-'
      }

      return this.listedProcesses.map(x => x.totalTeus).reduce((prev, curr) => prev + curr, 0)
    },
    remainingBookings () {
      if (!this.booking.teus_contratados) {
        return '-'
      }

      return this.booking.teus_contratados - this.usedBookings
    },
    contractStatus () {
      if (!this.booking.situacao) {
        return {
          color: '#000000',
          label: '-'
        }
      }

      return this.statusList[this.booking.situacao - 1]
    },
    groupStatus () {
      if (!this.groupInfo) {
        return {
          color: '#000000',
          label: '-'
        }
      }

      return this.statusList[this.groupInfo.status - 1]
    }
  }
}
</script>

<style scoped>
  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }

  tr.internal {
    background-color: #fcf8e3;
  }

  .royalc-btn-close {
    border: none;
    cursor: pointer;
    font-weight: bold;
    color: #84754E;
    background: transparent;
  }

  .custom-label {
    color: grey;
  }

  .btn-a-color {
    color: #534B41 !important;
  }
</style>
